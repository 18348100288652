<template>
  <cs-button-modal-add
    id="modal-add-componente"
    title="Aggiungi Componente"
    :loading="loading"
  >
    <validation-observer ref="rules">
      <b-form @submit.prevent="">
        <b-row>
          <b-col cols="12">

            <cs-validation
              label="Codice Opera Globale"
              rules="required"
            >
              <template slot-scope="props">
                <cs-input
                  id="cog"
                  v-model="componente.codice"
                  label="Codice Opera Globale"
                  :state="props.errors.length === 0 ? null : false"
                />
              </template>

            </cs-validation>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row>
      <b-col cols="12">
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-col>
    </b-row>
  </cs-button-modal-add>
</template>

<script>

export default {
  props: {
    operaId: {
      type: String,
      required: true,
    },
    parzialeId: {
      type: String,
      required: true,
    },
    parteId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      componente: {
        codice: null,
      },
    }
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.opere.componente_add(this.operaId, this.parzialeId, this.parteId, this.componente)
            .then(result => {
              this.$emit('onAdd', result.data)
              this.hideModal('modal-add-componente')
              this.componente = {
                codice: null,
              }
            })
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>

<template>
  <CsTableCard
    card-header="Elenco Componenti"
    :rows="rows"
    :columns="columns"
    :loading="loading"
  >
    <template slot="topLeft">
      <aggiungi
        v-if="canAdd"
        :opera-id="opera.id"
        :parziale-id="parziale.id"
        :parte-id="parte.id"
        @onAdd="addRow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :props="props"
        :show-actions="canUpdate || canRemove"
      >
        <aggiorna-componente
          v-if="canUpdate"
          :opera-id="opera.id"
          :componente="props.row"
        />
        <cs-table-action-item-delete
          v-if="canRemove"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>
  </CsTableCard>
</template>

<script>

import AggiornaComponente from './Aggiorna.vue'
import Aggiungi from './Aggiungi.vue'

export default {
  components: { AggiornaComponente, Aggiungi },
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Identificativo',
          field: 'identificativo',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nome Materiale',
          field: 'materiale',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Identificativo Materiale',
          field: 'materialeId',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Scheda',
          field: 'scheda',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      opera: {
        id: '00000000-0000-0000-0000-000000000000',
      },
      parziale: {
        id: '00000000-0000-0000-0000-000000000000',
      },
      parte: {
        id: '00000000-0000-0000-0000-000000000000',
      },
      canUpdate: this.$grants.OPERE_COMPONENTI_AGGIORNA.can,
      canAdd: this.$grants.OPERE_COMPONENTI_AGGIUNGI.can,
      canRemove: this.$grants.OPERE_COMPONENTI_ELIMINA.can,
    }
  },
  created() {
    if (!this.$grants.OPERE_COMPONENTI_VISUALIZZA.can) this.$routing.ERRORE401.push()

    const routeParams = this.$route.params

    this.$remote.opere.parte_single(routeParams.id, routeParams.operaParzialeId, routeParams.parteId)
      .then(res => {
        this.opera = res.data
        this.parziale = res.data.parziale
        this.parte = res.data.parte
        const value = {
          id: routeParams.id,
          nome: res.data.nome,
          operaParzialeId: routeParams.operaParzialeId,
          numeroOperaParziale: `Opera Parziale ${this.parziale.numero}`,
          nomeParte: `${this.parte.nome} ${this.parte.numero}`,
        }
        this.setBreadcrumbData(this.$store, this.$route.name, value)
        this.rows = this.parte.componenti
        this.loading = false
      })
      .catch(err => {
        this.showReposonseMessage(err)
        this.$routing.ERRORE404.push()
      })
  },
  methods: {
    addRow(params) {
      this.addRowAtStart(this.rows, params)
      this.showAddSuccessMessage()
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.opere.componente_remove(this.opera.id, this.parziale.id, this.parte.id, params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
